import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appConfig } from '../../owner.config';
import { Observable } from 'rxjs';
import { Owner } from '../models/communications.interface';
import { AnimalResponse } from '../models/branding.interface';

@Injectable({
  providedIn: 'root'
})
export class OwnerFamilyService {
  public token = localStorage.getItem('token');
  public headers: HttpHeaders;

  constructor(private http: HttpClient) {
    if (this.token) {
      this.headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    } else {
      this.headers = new HttpHeaders();
    }
  }

  getAnimalInfo(): Observable<AnimalResponse[]> {
    const url = `${appConfig.apiUrl}Owner/Animals`;
    return this.http.get<AnimalResponse[]>(url, { headers: this.headers });
  }

  getAnimalProfile(petszelAnimalId: string) {
    // Store the petszelAnimalId in local storage
    localStorage.setItem('petszelAnimalId', petszelAnimalId);

    const url = `${appConfig.apiUrl}Owner/Animals/${petszelAnimalId}`;
    return this.http.get(url, { headers: this.headers });
  }

  getDogBreed() {
    const url = `${appConfig.apiUrl}PetszelConstants/Breeds/Dog`;
    return this.http.get(url, { headers: this.headers });
  }

  getCatBreed() {
    const url = `${appConfig.apiUrl}PetszelConstants/Breeds/Cat`;
    return this.http.get(url, { headers: this.headers });
  }

  putNewPetInfo(formData: any) {
    const url = `${appConfig.apiUrl}Owner/Animals`;
    return this.http.put(url, formData, { headers: this.headers });
  }

  getOwnerInfo(): Observable<any> {
    const url = `${appConfig.apiUrl}Owner`;
    return this.http.get(url, { headers: this.headers });
  }

  putOwnerInfo(ownerData: Owner): Observable<any> {
    const url = `${appConfig.apiUrl}Owner`;
    return this.http.put(url, ownerData, { headers: this.headers });
  }

  postPassword(data: any): Observable<any> {
    const url = `${appConfig.apiUrl}Owner/ChangePassword`;
    return this.http.post(url, data, { headers: this.headers });
  }

  getMicrochipCompanies(): Observable<string[]> {
    const url = `${appConfig.apiUrl}PetszelConstants/MicrochipCompanies`;
    return this.http.get<string[]>(url);
  }
}